import * as CONSTANTS from './constants'
import logging from './logging'
import { MEClientService } from './me-client-service'
import {
  MEDeviceEventService,
  MEEventsRequestData,
  MEEventAttributes,
  MEEvent,
  PostEventsResult
} from './me-device-event-service'
import { PushNotificationSupportInfo } from './push-notification-support-info'
import { NavigatorInfo } from './navigator-info'
import { MEWebPushDb } from './me-web-push-db'
import { IndexDb } from './index-db'
import { MEV3ApiRequest } from './me-v3-api-request'
import { SafariPushService, VapidPushService } from './push-service'

import { v4 as uuidv4 } from 'uuid'
import { IPushService } from './push-service/push-service.types'
import EventEmitter from './event-emitter'
import * as Cache from './local-storage'
import * as JWT from './jwt'
import * as Utils from './utils'
import { StorageWithLog } from './storage-with-log'

const { Logger, enableLogger } = logging

type ChainFunction = (param: any) => Promise<any> | any

/**
 * EmarsysWebPush class is responsible for subscription for notifications.
 * This is the entry point what have to be called from site's index page.
 */
export default class MeWebPush {
  private readonly navigator: Navigator
  private readonly window: Window
  private meClientService?: MEClientService
  private meDeviceEventService?: MEDeviceEventService
  private readonly pushNotificationSupportInfo: PushNotificationSupportInfo
  private readonly navigatorInfo: NavigatorInfo
  private readonly meWebPushDb: MEWebPushDb
  private readonly storageWithLog: StorageWithLog
  private readonly eventEmitter: EventEmitter
  private readonly permissionPromises: { [key: string]: Promise<ChainFunction> }
  private pushService?: IPushService
  private ready: boolean = false

  constructor (window: Window, navigator: Navigator) {
    this.navigator = navigator
    this.window = window
    this.navigatorInfo = NavigatorInfo.create(navigator)
    this.pushNotificationSupportInfo = PushNotificationSupportInfo.create(this.navigatorInfo, window)
    this.meWebPushDb = MEWebPushDb.create(IndexDb.create())
    this.storageWithLog = new StorageWithLog(this.meWebPushDb)
    this.eventEmitter = new EventEmitter()
    this.permissionPromises = {}
    if (this.pushNotificationSupportInfo.canUsePromises()) {
      this.permissionPromises = {
        [CONSTANTS.EVENT_ON_PERMISSION_DENIED]: new Promise(
          resolve => this.eventEmitter.once(CONSTANTS.EVENT_ON_PERMISSION_DENIED, resolve)
        ),
        [CONSTANTS.EVENT_ON_PERMISSION_PROMPT]: new Promise(
          resolve => this.eventEmitter.once(CONSTANTS.EVENT_ON_PERMISSION_PROMPT, resolve)
        ),
        [CONSTANTS.EVENT_ON_PERMISSION_GRANTED]: new Promise(
          resolve => this.eventEmitter.once(CONSTANTS.EVENT_ON_PERMISSION_GRANTED, resolve)
        )
      }
    }
  }

  push (command: SdkCommand) {
    if (typeof command === 'function') {
      this.registerOrHandleOnReadyCallback(command)
      return
    }

    if (!Array.isArray(command)) {
      throw new Error('Invalid command!')
    }

    switch (command[0]) {
      case 'init':
        this
          .init(command[1])
          .then(() => Logger.debug('Initialized'))
          .catch(err => Logger.error(err, 'Init failed!'))
        break

      case CONSTANTS.EVENT_ON_READY:
        this.registerOrHandleOnReadyCallback(command[1])
        break

      case CONSTANTS.EVENT_ON_SUBSCRIBE:
      case CONSTANTS.EVENT_ON_UNSUBSCRIBE:
      case CONSTANTS.EVENT_ON_SW_INIT_ERROR:
        this.registerEventCallback(command[0], command[1])
        break

      case CONSTANTS.EVENT_ON_PERMISSION_DENIED:
      case CONSTANTS.EVENT_ON_PERMISSION_PROMPT:
      case CONSTANTS.EVENT_ON_PERMISSION_GRANTED:
        this.registerChangePermissionCallback(command[0], command[1])
        break

      default:
        Logger.warn(`WARN: Command "${JSON.stringify(command)}" not yet implemented!`)
    }
  }

  public async customEvent (name: string, attributes?: MEEventAttributes): Promise<boolean> {
    try {
      const eventData = {
        dnd: true,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        events: [{
          type: 'custom',
          name,
          timestamp: new Date().toISOString(),
          attributes: {
            ...attributes,
            'me:origin': 'webpush'
          }
        } as MEEvent],
        clicks: [],
        viewedMessages: []
      }
      let result: PostEventsResult = await this.meDeviceEventService!.postEvents(eventData)
      if (!result.success && result.statusCode === 401) {
        Logger.log('Contact token seems outdated, try to refresh and send again...')
        result = await this.retrySendAfterContactTokenRefresh(eventData)
      }
      Logger.debug('Sent custom event', name, JSON.stringify(attributes), JSON.stringify(result))
      return result.success
    } catch (err) {
      Logger.error('Error sending custom event', err.message, err)
      return false
    }
  }

  public async subscribe (): Promise<void> {
    const pushService = this.getPushService()
    const isPermissionDefault = pushService.isPermissionDefault()

    let permission: NotificationPermission
    if (isPermissionDefault) {
      this.eventEmitter.emit(CONSTANTS.EVENT_ON_PERMISSION_PROMPT)
      // Sometimes `Notification.requestPermission()` doesn't resolve (on line157 `pushService.askPermission()`)
      // Add an interval to check the permission status, and `subscribe()` again if it couldn't resolve.
      const permissionCheckInterval = setInterval(() => {
        if (pushService.isPermissionDefault()) { return } // if still showing permission popup
        clearInterval(permissionCheckInterval)
        if (!permission) { // if `Notification.requestPermission()` doesn't resolve
          void this.subscribe()
        }
      }, 1000)
      permission = await pushService.askPermission().catch(err => {
        Logger.error('Safari ask permission error', err)
        return CONSTANTS.PERMISSION_DENIED
      })
      clearInterval(permissionCheckInterval)
    } else {
      permission = pushService.getPermission()
    }
    const isDeviceRegistered = await this.isFullyRegistered(false)

    if (permission === CONSTANTS.PERMISSION_GRANTED) {
      const contactInfo: {} | ContactInfo | undefined = await this.getLoggedInContact()
      Logger.debug('User granted permission for push notifications')
      this.eventEmitter.emit(CONSTANTS.EVENT_ON_PERMISSION_GRANTED)

      if (!isDeviceRegistered) {
        Logger.debug('Triggering push service unsubscribe')
        await pushService.unsubscribe()
        Logger.debug('Triggering push service subscribe')
      } else {
        Logger.debug('Triggering subscribe for token update')
      }

      await pushService.subscribe(contactInfo)
      await this.meWebPushDb.setLastUsedAt()
      this.eventEmitter.emit(CONSTANTS.EVENT_ON_SUBSCRIBE)
      return
    }

    if (permission === CONSTANTS.PERMISSION_DENIED) {
      Logger.log('User has declined push permission')
      if (isDeviceRegistered) {
        await pushService.unsubscribe()
      }
      this.eventEmitter.emit(CONSTANTS.EVENT_ON_PERMISSION_DENIED)
    }
  }

  public async unsubscribe (): Promise<void> {
    try {
      Logger.debug('Unsubscribing...')
      await this.getPushService().unsubscribe()
      Cache.setRegistrationStatus(CONSTANTS.DEVICE_REGISTRATION_STATUS_UNREGISTERED)
      this.eventEmitter.emit(CONSTANTS.EVENT_ON_UNSUBSCRIBE)
    } catch (err) {
      Logger.error(err, 'Error occurred during unsubscribe')
    }
  }

  public async getParams () {
    const applicationCode = await this.meWebPushDb.getAppCode()
    const clientId = await this.meWebPushDb.getClientIdForAppCode(applicationCode)
    const initParams = await this.meWebPushDb.getInitParams()
    const pushToken = await this.meWebPushDb.getPushToken()
    const serviceWorkerScope = await this.meWebPushDb.getServiceWorkerScope()
    const serviceWorkerVersion = await this.meWebPushDb.getServiceWorkerVersion()
    const sdkVersion = await this.meWebPushDb.getSdkVersion()
    const meClientServiceApiBaseUrl = await this.meWebPushDb.getMeClientServiceApiBaseUrl()
    const meDeviceEventServiceApiBaseUrl = await this.meWebPushDb.getMeDeviceEventServiceApiBaseUrl()
    const clientState = await this.meWebPushDb.getClientState()
    const contactToken = await this.meWebPushDb.getContactToken()
    const refreshToken = await this.meWebPushDb.getRefreshToken()

    return {
      applicationCode,
      clientId,
      pushToken,
      serviceWorkerScope,
      serviceWorkerVersion,
      sdkVersion,
      meClientServiceApiBaseUrl,
      meDeviceEventServiceApiBaseUrl,
      clientState,
      contactToken,
      refreshToken,
      ...initParams
    }
  }

  public async getLoggedInContact (): Promise<ContactInfo | {} | undefined> {
    const clientState = await this.storageWithLog.getClientState({ level: 'info' })
    if (!clientState) {
      return undefined
    }

    const decodedClientState = JWT.decode(clientState)
    if (!decodedClientState) {
      Logger.error('Decoding failed', JSON.stringify(clientState))
      return undefined
    }
    const contactField = decodedClientState.contactField
    if (contactField === undefined) {
      return undefined
    }

    if (contactField === null || Object.keys(contactField).length === 0) {
      return {} // the anonymous contact!
    }

    if ((contactField.contactFieldId !== undefined) && contactField.contactFieldValue) {
      return {
        fieldId: contactField.contactFieldId,
        fieldValue: contactField.contactFieldValue,
        encrypted: (typeof contactField.contactFieldEncryped === 'boolean') ? contactField.contactFieldEncryped : false
      }
    } else {
      Logger.error('contactField information incomplete', JSON.stringify(contactField))
      return undefined
    }
  }

  public async isLoggedIn (): Promise<boolean> {
    const contactInfo = await this.getLoggedInContact()
    return !(Utils.isEmptyObject(contactInfo) || contactInfo === undefined)
  }

  public async setOpenIdAuthenticatedContact (contactInfo: OpenIdContactInfo): Promise<boolean> {
    this.assertOpenIdContactInfo(contactInfo)
    return this.meClientService!.linkClientToContact(contactInfo)
  }

  public async login (contactInfo?: Omit<ContactInfo, 'encrypted'>): Promise<boolean> {
    this.assertContactInfo(contactInfo)
    const finalContactInfo = (contactInfo !== undefined) ? { ...contactInfo, encrypted: false } : contactInfo
    return this.meClientService!.linkClientToContact(finalContactInfo)
  }

  public async logout (): Promise<boolean> {
    return this.meClientService!.linkClientToContact()
  }

  public async removeAllDeviceData (): Promise<void> {
    await this.cleanupClientOnBackend(this.meClientService!)
    await this.meWebPushDb.clearAll()
  }

  public async isSubscribed (): Promise<boolean> {
    return this.isFullyRegistered()
  }

  public async registerClient (contactInfo?: Omit<ContactInfo, 'encrypted'>): Promise<boolean> {
    this.assertContactInfo(contactInfo)
    const linkContactInfo = contactInfo ? { ...contactInfo, encrypted: false } : undefined
    const result = await this.meClientService!.storeClientDetails() &&
                   await this.meClientService!.linkClientToContact(linkContactInfo)
    return result
  }

  public async getClientId (): Promise<string | undefined> {
    const appCode = await this.meWebPushDb.getAppCode()

    if (appCode === undefined) {
      Logger.log('No APP code found in environment')
      return undefined
    }

    const clientId = await this.meWebPushDb.getClientIdForAppCode(appCode)
    if (clientId === undefined) {
      Logger.log(`No client ID for APP code "${appCode}" found`)
    }
    return clientId
  }

  private assertContactInfo (contactInfo?: Omit<ContactInfo, 'encrypted'>) {
    // tslint:disable-next-line
    if (contactInfo && ((contactInfo.fieldId === undefined) || !contactInfo.fieldValue)) {
      throw new Error(`Incomplete contact info: ${JSON.stringify(contactInfo)}`)
    }
  }

  private assertOpenIdContactInfo (contactInfo: OpenIdContactInfo) {
    // tslint:disable-next-line
    if ((contactInfo.fieldId === undefined) || !contactInfo.openIdToken) {
      throw new Error(`Incomplete contact info: ${JSON.stringify(contactInfo)}`)
    }
  }

  /**
   * Subscribe for push notifications and registers the subscription.
   * This function have to be called from the site's page.
   */
  private async init (params: IInitParams): Promise<void> {
    if (!this.pushNotificationSupportInfo.pushNotificationsSupported()) {
      return Promise.reject(new Error('Web push not supported'))
    }

    this.enableLogging(params.enableLogging)

    await this.setupMeClientService(params.clientServiceApiBaseUrl)

    await this.setupMeDeviceEventService(params.deviceEventServiceApiBaseUrl)

    const appCode = await this.checkApplicationCode(this.meClientService!, params.applicationCode)

    await this.checkClientId(appCode)

    await this.persistPlatformInfo(params)

    await this.persistConfig(params)

    // TODO: We could now report an "app open" with a frequency capping of 1h here...

    await this.initPushNotifications(params)

    // initialization is done, so any registered callback may be invoked now
    this.eventEmitter.emit(CONSTANTS.EVENT_ON_READY)
    this.ready = true
  }

  private enableLogging (enable?: boolean) {
    enableLogger(enable === true)
  }

  private async setupMeClientService (baseUrl?: string): Promise<void> {
    const meClientServiceApiBaseUrl = baseUrl ?? CONSTANTS.defaultClientServiceApiBaseUrl
    this.meClientService = MEClientService.create(
      meClientServiceApiBaseUrl, MEV3ApiRequest.create(), this.meWebPushDb
    )
    await this.meWebPushDb.setMeClientServiceApiBaseUrl(meClientServiceApiBaseUrl)
  }

  private async setupMeDeviceEventService (baseUrl?: string): Promise<void> {
    const meDeviceEventServiceApiBaseUrl = baseUrl ?? CONSTANTS.defaultDeviceEventServiceApiBaseUrl
    this.meDeviceEventService = MEDeviceEventService.create(
      meDeviceEventServiceApiBaseUrl, MEV3ApiRequest.create(), this.meWebPushDb
    )
    await this.meWebPushDb.setMeDeviceEventServiceApiBaseUrl(meDeviceEventServiceApiBaseUrl)
  }

  private async checkApplicationCode (meClientService: MEClientService, applicationCode?: string): Promise<string> {
    const appCode = await this.meWebPushDb.getAppCode()

    if (!applicationCode) {
      return Promise.reject(new Error('Can\'t find application code!'))
    }

    const noSavedAppCode = !appCode
    const appCodeChanged = appCode && appCode !== applicationCode
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (noSavedAppCode || appCodeChanged) {
      await this.cleanupClientOnBackend(meClientService)
      await this.meWebPushDb.clearAll()
      await this.meWebPushDb.setAppCode(applicationCode)
    }

    return applicationCode
  }

  private async checkClientId (appCode: string): Promise<void> {
    const clientId = await this.meWebPushDb.getClientIdForAppCode(appCode)

    if (!clientId) {
      Logger.log('No ClientId stored. Creating one...')
      const id = appCode + '_' + uuidv4()
      await this.meWebPushDb.setClientIdForAppCode(id, appCode)
    } else {
      Logger.log('ClientId exists:', clientId)
    }
  }

  private async persistPlatformInfo (params: IInitParams): Promise<void> {
    const completeNavigatorInfo = this.navigatorInfo.getAll()
    await Promise.all([
      this.meWebPushDb.setPlatform(completeNavigatorInfo.browser),
      this.meWebPushDb.setApplicationVersion(params.applicationVersion ?? CONSTANTS.defaultApplicationVersion),
      this.meWebPushDb.setDeviceModel(completeNavigatorInfo.userAgent),
      this.meWebPushDb.setOsVersion(completeNavigatorInfo.browserVersion),
      this.meWebPushDb.setLanguage(completeNavigatorInfo.language),
      this.meWebPushDb.setTimezone(completeNavigatorInfo.timezone),
      this.meWebPushDb.setSdkVersion(__VERSION__)
    ])
  }

  private async persistConfig (params: IInitParams): Promise<void> {
    const serviceWorkerUrl = params.serviceWorker ? params.serviceWorker.url : undefined
    const serviceWorkerScope = Utils.determineServiceWorkerScope(params.serviceWorker)
    const serviceWorkerAppPublicKey = params.serviceWorker ? params.serviceWorker.applicationServerPublicKey : undefined
    await Promise.all([
      this.meWebPushDb.setServiceWorkerUrl(serviceWorkerUrl),
      this.meWebPushDb.setServiceWorkerScope(serviceWorkerScope),
      this.meWebPushDb.setApplicationServerPublicKey(serviceWorkerAppPublicKey),
      this.meWebPushDb.setDefaultNotificationIcon(params.defaultNotificationIcon),
      this.meWebPushDb.setDefaultNotificationTitle(params.defaultNotificationTitle),
      this.meWebPushDb.setWebsitePushId(params.safariWebsitePushID),
      this.meWebPushDb.setPushPackageServiceUrl(params.safariPushPackageServiceUrl),
      this.meWebPushDb.setLoggingEnabled(Boolean(params.enableLogging).valueOf()),
      this.meWebPushDb.setMeClientServiceApiBaseUrl(
        params.clientServiceApiBaseUrl ?? CONSTANTS.defaultClientServiceApiBaseUrl
      ),
      this.meWebPushDb.setMeDeviceEventServiceApiBaseUrl(
        params.deviceEventServiceApiBaseUrl ?? CONSTANTS.defaultDeviceEventServiceApiBaseUrl
      ),
      this.meWebPushDb.setLastUsedAt()
    ])
  }

  private async initPushNotifications (params: IInitParams): Promise<void> {
    const enableMacSafariVapid: boolean = Boolean(params.enableMacSafariVapid).valueOf()
    const initParamsToPersist = {
      ...params,
      enableLogging: Boolean(params.enableLogging).valueOf(),
      enableMacSafariVapid
    }
    await this.meWebPushDb.setInitParams(initParamsToPersist)

    await this.setupPushService(enableMacSafariVapid)

    try {
      await this.initialPushServiceProcessing(initParamsToPersist)
    } catch (err) {
      Logger.error(err, 'Internal error')
    }
  }

  private async setupPushService (enableMacSafariVapid: boolean): Promise<void> {
    if (!this.meClientService) {
      throw new Error('Me client service connection is not set up!')
    }
    if (this.pushNotificationSupportInfo.canUseSafariPush() &&
      (!enableMacSafariVapid || !this.pushNotificationSupportInfo.canUseServiceWorkers())) {
      const config = await this.buildApnsApiRegistrationConfig()
      this.pushService = new SafariPushService(this.meWebPushDb, config, this.meClientService)
      return
    }

    if (this.pushNotificationSupportInfo.canUseServiceWorkers()) {
      const vapidPushService: VapidPushService = new VapidPushService(this.meWebPushDb, this.meClientService)
      await vapidPushService.updateServiceWorker()
      this.pushService = vapidPushService
    }
  }

  private async initialPushServiceProcessing (initParams: IInitParams) {
    const pushService = this.getPushService()
    const permission = pushService.getPermission()

    if (permission === CONSTANTS.PERMISSION_GRANTED) {
      await this.meWebPushDb.setLastPermissionStatus(permission)
    }

    const isResubscribeNeeded = await pushService.isResubscribeNeeded()

    if (isResubscribeNeeded) {
      Logger.log('Re-subscribe is needed.')
      await this.unsubscribe()
    }

    const isRegistered = await this.isFullyRegistered(false)

    switch (permission) {
      case CONSTANTS.PERMISSION_PROMPT:
        // device can't be registered if permission is "default" (so "prompt")
        if (isRegistered) {
          Logger.debug('Unsubscribing in PROMPT state')
          this.eventEmitter.emit(CONSTANTS.EVENT_ON_PERMISSION_PROMPT)
          await this.unsubscribe()
        }

        break

      case CONSTANTS.PERMISSION_DENIED:
        if (isRegistered) {
          await this.unsubscribe()
        }
        this.eventEmitter.emit(CONSTANTS.EVENT_ON_PERMISSION_DENIED)
        break

      case CONSTANTS.PERMISSION_GRANTED:
        if (!MeWebPush.isUnregistered()) {
          await this.subscribe()
        }
        this.eventEmitter.emit(CONSTANTS.EVENT_ON_PERMISSION_GRANTED)
        break
    }
  }

  private async cleanupClientOnBackend (meClientService: MEClientService): Promise<void> {
    Logger.debug('Cleanup of client information on backend side')
    const { exists, pushTokenExists, identified } = await Utils.checkDevice(this.meWebPushDb)

    if (!exists) {
      Logger.debug('The browser is not registered at all.')
      return
    }

    if (pushTokenExists) {
      const result = await meClientService.removePushToken()
      Logger.log('Removed registered push token:', result)
    }

    if (identified) {
      const result = await meClientService.linkClientToContact()
      Logger.log('Assigned anonymous contact:', result)
    }
  }

  private getPushService (): IPushService {
    if (this.pushService) {
      return this.pushService
    } else {
      throw new Error('Push service is not set up!')
    }
  }

  private async buildApnsApiRegistrationConfig (): Promise<ApnsApiRegistrationConfig> {
    const safariWebsitePushID = await this.meWebPushDb.getWebsitePushId()
    const applicationCode = await this.meWebPushDb.getAppCode()
    const clientId = await this.meWebPushDb.getClientIdForAppCode(applicationCode)
    if (safariWebsitePushID && applicationCode && clientId) {
      const safariPushPackageServiceUrl = await this.meWebPushDb.getPushPackageServiceUrl()
      return {
        clientId,
        applicationCode,
        safariWebsitePushID,
        safariPushPackageServiceUrl: safariPushPackageServiceUrl ?? CONSTANTS.defaultSafariPushPackageServiceUrl
      }
    } else {
      throw new Error('safariWebsitePushID must be specified for Safari support!')
    }
  }

  private onReadyHandler (cmd: HandlerFn) {
    if (this.ready) {
      cmd(undefined) // TODO: Pass the API (me client service & DES)
    } else {
      this.eventEmitter.on(CONSTANTS.EVENT_ON_READY, (params) => cmd(undefined, params))
    }
  }

  private registerOrHandleOnReadyCallback (callback: SdkOnReadyCallback) {
    this.onReadyHandler(callback)
  }

  private registerEventCallback (name: MeWebSdkEvent, callback: HandlerFn) {
    this.eventEmitter.on(name, (params: any) => {
      return callback(undefined, params) // TODO: Pass API instead of undefined here
    })
  }

  private registerChangePermissionCallback (name: MeWebSdkEvent, callback: HandlerFn) {
    const currentPromise = this.permissionPromises[name]
    // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/strict-boolean-expressions
    if (!currentPromise) {
      return
    }

    const registerCallbackForFurtherEvents = this.registerEventCallback.bind(this)
    currentPromise
      .then(() => {
        // after the first occurrence was handled register the callback in the "normal"
        // way so that subsequent events are forwarded too.
        registerCallbackForFurtherEvents(name, callback)
        return callback(undefined) // TODO: Replace undefined with this.API...
      })
      .catch((err) => Logger.error(err, 'Error while handling permission change callback.'))
  }

  private async isFullyRegistered (useCache: boolean = true): Promise<boolean> {
    const status: string | undefined = Cache.getRegistrationStatus()
    Logger.debug('Local status is', status)

    if (status === undefined || !useCache) {
      return this.updateRegistrationStatus()
    } else {
      return Promise.resolve(status === CONSTANTS.DEVICE_REGISTRATION_STATUS_REGISTERED)
    }
  }

  private static isUnregistered (): boolean {
    const status = Cache.getRegistrationStatus()
    return (status === CONSTANTS.DEVICE_REGISTRATION_STATUS_UNREGISTERED)
  }

  private async updateRegistrationStatus (): Promise<boolean> {
    Logger.debug('Checking device...')
    const isRegistered = await this.pushService!.isRegistered()
    Logger.debug('Device exists & has token', isRegistered)
    const status = isRegistered
      ? CONSTANTS.DEVICE_REGISTRATION_STATUS_REGISTERED
      : CONSTANTS.DEVICE_REGISTRATION_STATUS_UNREGISTERED
    Cache.setRegistrationStatus(status)
    return status === CONSTANTS.DEVICE_REGISTRATION_STATUS_REGISTERED
  }

  private async retrySendAfterContactTokenRefresh (eventsData: MEEventsRequestData): Promise<PostEventsResult> {
    const success = await this.meClientService!.generateAccessToken()
    if (!success) {
      Logger.error('Refresh of access token failed!')
      return { success: false }
    }
    return this.meDeviceEventService!.postEvents(eventsData)
  }

  public static create (window: Window, navigator: Navigator): MeWebPush {
    return new MeWebPush(window, navigator)
  }
}
