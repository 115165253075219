export const defaultClientServiceApiBaseUrl = 'https://me-client.eservice.emarsys.net/v3'
export const defaultDeviceEventServiceApiBaseUrl = 'https://mobile-events.eservice.emarsys.net/v3'
export const defaultSafariPushPackageServiceUrl = 'https://me-client.eservice.emarsys.net'

export const defaultApplicationVersion = '0.0.0'

export const loginOverloadProtectionTime = 3600000

export const pushTitleProperty = 'title'

// property names in push notification data
export const pushLinkProperty = 'link'
export const pushIconProperty = 'icon'
export const pushImageProperty = 'image'
export const pushActionsProperty = 'actions'

// ingester requests password
export const applicationPassword = 'not-used'

// indexDb keys
export const dbKeyDefaultNotificationTitle = 'pushDefaultNotificationTitle'
export const dbKeyDefaultNotificationIcon = 'pushDefaultNotificationIcon'
export const dbKeyApplicationCode = 'emarsysApplicationCode'
export const dbKeyMeClientServiceApiBaseUrl = 'meClientServiceApiBaseUrl'
export const dbKeyMeDeviceEventServiceApiBaseUrl = 'meDeviceEventServiceApiBaseUrl'
export const dbKeyServiceWorkerUrl = 'serviceWorkerUrl'
export const dbKeyServiceWorkerScope = 'serviceWorkerScope'
export const dbKeyApplicationServerPublicKey = 'applicationServerPublicKey'
export const dbKeyWebsitePushId = 'websitePushId'
export const dbKeyPushPackageServiceUrl = 'pushPackageServiceUrl'
export const dbKeyLastPermissionStatus = 'lastPermissionStatus'
export const dbKeyInitParams = 'initParams'
export const dbKeyBrowserId = 'browserId'
export const dbKeyBrowserIds = 'browserIds'
export const dbKeyXClientState = 'xClientState'
export const dbKeyContactToken = 'contactToken'
export const dbKeyRefreshToken = 'refreshToken'
export const dbKeySdkVersion = 'sdkVersion'
export const dbKeyServiceWorkerVersion = 'serviceWorkerVersion'
export const dbKeyPushToken = 'pushToken'
export const dbKeyContactFieldId = 'contactFieldId'
export const dbKeyContactFieldValue = 'contactFieldValue'
export const dbKeyPlatform = 'platform'
export const dbKeyApplicationVersion = 'applicationVersion'
export const dbKeyDeviceModel = 'deviceModel'
export const dbKeyOsVersion = 'osVersion'
export const dbKeyLanguage = 'language'
export const dbKeyTimezone = 'timezone'
export const dbKeyLoggingEnabled = 'loggingEnabled'
export const dbKeyLastUsedAt = 'lastUsedAt'

// localStorage keys
export const lsKeyLastLoginTime = 'emarsysWebpushLastLoginTime'
export const lsKeyLastLoginToken = 'emarsysWebpushLastLoginToken'
export const lsKeyLastContactFieldId = 'emarsysWebpushLastContactFieldId'

// ME endpoints
export const meLogin = '/users/login'
export const meLogout = '/users/logout'
export const meOpen = '/events/message_open'
export const meCustomEvent = '/events/'

// Indexed DB
export const indexedDbName = 'EMARSYS_WEBPUSH_STORE'
export const indexedDbVersion = 1

// Permissions
export const PERMISSION_DENIED = 'denied'
export const PERMISSION_GRANTED = 'granted'
export const PERMISSION_PROMPT = 'default'

// Events
export const EVENT_ON_READY = 'onReady'
export const EVENT_ON_SUBSCRIBE = 'onSubscribe'
export const EVENT_ON_UNSUBSCRIBE = 'onUnsubscribe'
export const EVENT_ON_PERMISSION_PROMPT = 'onPermissionPrompt'
export const EVENT_ON_PERMISSION_DENIED = 'onPermissionDenied'
export const EVENT_ON_PERMISSION_GRANTED = 'onPermissionGranted'
export const EVENT_ON_SW_INIT_ERROR = 'onSWInitError'
export const EVENT_ON_PUSH_DELIVERY = 'onPushDelivery'
export const EVENT_ON_PUT_NEW_MESSAGE_TO_INBOX_STORE = 'onPutNewMessageToInboxStore'
export const EVENT_ON_UPDATE_INBOX_MESSAGES = 'onUpdateInboxMessages'
export const EVENT_ON_SHOW_NOTIFICATION_PERMISSION_DIALOG = 'onShowNotificationPermissionDialog'
export const EVENT_ON_HIDE_NOTIFICATION_PERMISSION_DIALOG = 'onHideNotificationPermissionDialog'

// LocalStore
export const KEY_DEVICE_REGISTRATION_STATUS = 'registrationStatus'

// Device registration status
export const DEVICE_REGISTRATION_STATUS_REGISTERED: string = 'registered'
export const DEVICE_REGISTRATION_STATUS_UNREGISTERED: string = 'unregistered'
