import logging from './logging'
import { MEV3ApiRequest } from './me-v3-api-request'
import { MEWebPushDb } from './me-web-push-db'
import { StorageWithLog } from './storage-with-log'

const { Logger } = logging

type ContactRequestBodyData = {
  anonymous: boolean
  body: { contactFieldId: number, contactFieldValue: string, contactFieldEncrypted: boolean } |
  { contactFieldId: number, openIdToken: string } | {}
}

export type ClientDetails = {
  platform: string
  applicationVersion?: string
  deviceModel?: string
  osVersion?: string
  sdkVersion?: string
  language?: string
  timezone?: string
}

export class MEClientService {
  private readonly storage: MEWebPushDb
  private readonly storageWithLog: StorageWithLog
  private readonly baseUrl: string
  private meClientServiceRequest: MEV3ApiRequest

  constructor (
    baseUrl: string,
    meClientServiceRequest: MEV3ApiRequest,
    storage: MEWebPushDb
  ) {
    this.storage = storage
    this.storageWithLog = new StorageWithLog(storage)
    this.baseUrl = `${baseUrl}/domains`
    this.meClientServiceRequest = meClientServiceRequest
  }

  /**
   * Create or update the information which is related to a specific browser
   */
  async storeClientDetails (): Promise<boolean> {
    const clientId = await this.storageWithLog.getClientId()
    if (!clientId) {
      return false
    }
    const apiEndpoint = await this.apiEndpoint('client')
    const clientState = await this.storage.getClientState()
    const clientDetails = await this.getClientDetails()
    const response = await this.meClientServiceRequest.post(apiEndpoint, clientDetails, { clientId, clientState })
    if (response.status === 204) {
      await this.saveClientState(response)
      return true
    } else {
      const body = await response.json()
      Logger.error('Error storing client info', response.status, JSON.stringify(body))
      return false
    }
  }

  async linkClientToContact (contactInfo?: ContactInfo | OpenIdContactInfo): Promise<boolean> {
    const clientId = await this.storageWithLog.getClientId()
    const clientState = await this.storageWithLog.getClientState({ level: 'info' })
    if (!clientId || !clientState) {
      return false
    }
    const { anonymous, body } = this.toContactRequestBodyData(contactInfo)
    const apiEndpoint = `${await this.apiEndpoint('client/contact')}${anonymous ? '?anonymous=true' : ''}`
    const response = await this.meClientServiceRequest.post(apiEndpoint, body, { clientId, clientState })
    if (response.status === 200) {
      await this.saveClientState(response)
      const responseBody = await response.json()
      if (responseBody.contactToken && responseBody.refreshToken) {
        await Promise.all([
          this.storage.setContactToken(responseBody.contactToken),
          this.storage.setRefreshToken(responseBody.refreshToken)
        ])
        return true
      } else {
        Logger.error('At least one of the expected response parts missing!')
        return false
      }
    } else {
      const body = await response.json()
      Logger.error('Error linking contact to client', response.status, JSON.stringify(body))
      return false
    }
  }

  async generateAccessToken (): Promise<boolean> {
    const clientId = await this.storageWithLog.getClientId()
    const refreshToken = await this.storageWithLog.getRefreshToken()
    const clientState = await this.storageWithLog.getClientState()
    if (!clientId || !refreshToken || !clientState) {
      return false
    }
    const apiEndpoint = await this.apiEndpoint('client/contact-token')
    const body = { refreshToken }
    const response = await this.meClientServiceRequest.post(apiEndpoint, body, { clientId, clientState })
    if (response.status === 200) {
      const responseBody = await response.json()
      if (responseBody.contactToken) {
        await this.storage.setContactToken(responseBody.contactToken)
        return true
      } else {
        Logger.error('ContactToken is not part of response body!')
        return false
      }
    } else {
      const body = await response.json()
      Logger.log('Error refreshing the contact token', response.status, JSON.stringify(body))
      return false
    }
  }

  async registerPushToken (pushToken: string): Promise<boolean> {
    const clientId = await this.storageWithLog.getClientId()
    const clientState = await this.storageWithLog.getClientState()
    const contactToken = await this.storageWithLog.getContactToken({ message: 'Unable to register subscription as contactToken is missing!' })
    if (!clientId || !clientState || !contactToken) {
      return false
    }

    const apiEndpoint = await this.apiEndpoint('client/push-token')
    const body = { pushToken }
    const response = await this.meClientServiceRequest.put(apiEndpoint, body, { clientId, clientState, contactToken })
    if (response.status === 204) {
      await this.saveClientState(response)
      return true
    } else {
      const body = await response.json()
      Logger.error('Error registering the subscription', response.status, JSON.stringify(body))
      return false
    }
  }

  async removePushToken (): Promise<boolean> {
    Logger.info('Remove push token')
    const clientId = await this.storageWithLog.getClientId()
    const clientState = await this.storageWithLog.getClientState({ level: 'info' })
    const contactToken = await this.storageWithLog.getContactToken({ level: 'info' })
    if (!clientId || !clientState || !contactToken) {
      return false
    }
    const apiEndpoint = await this.apiEndpoint('client/push-token')
    const response = await this.meClientServiceRequest.delete(apiEndpoint, {}, { clientId, clientState, contactToken })
    if (response.status === 204) {
      await this.saveClientState(response)
      return true
    } else {
      const body = await response.json()
      Logger.error('Error removing a subscription', response.status, JSON.stringify(body))
      return true
    }
  }

  private async apiEndpoint (path: string): Promise<string> {
    const appCode = await this.storage.getAppCode()
    return `${this.baseUrl}/${appCode!}/${path}`
  }

  private async saveClientState (response: Response) {
    const clientState = response.headers.get('x-client-state')
    if (clientState) {
      await this.storage.setClientState(clientState)
    } else {
      Logger.error('Error: X-Client-State not found in response header!')
    }
  }

  private async getClientDetails (): Promise<ClientDetails> {
    const platform = await this.storage.getPlatform()
    if (platform) {
      const applicationVersion = await this.storage.getApplicationVersion()
      const deviceModel = await this.storage.getDeviceModel()
      const osVersion = await this.storage.getOsVersion()
      const sdkVersion = await this.storage.getSdkVersion()
      const language = await this.storage.getLanguage()
      const timezone = await this.storage.getTimezone()
      return {
        platform,
        applicationVersion,
        deviceModel,
        osVersion,
        sdkVersion,
        language,
        timezone
      }
    } else {
      throw new Error('platform not found in storage!')
    }
  }

  private toContactRequestBodyData (contactInfo?: ContactInfo | OpenIdContactInfo): ContactRequestBodyData {
    if (!contactInfo) {
      return {
        body: {},
        anonymous: true
      }
    }

    if ('openIdToken' in contactInfo) {
      return {
        body: {
          contactFieldId: contactInfo.fieldId,
          openIdToken: contactInfo.openIdToken
        },
        anonymous: false
      }
    }

    return {
      body: {
        contactFieldId: contactInfo.fieldId,
        contactFieldValue: contactInfo.fieldValue,
        contactFieldEncrypted: contactInfo.encrypted
      },
      anonymous: false
    }
  }

  static create (
    baseUrl: string,
    meClientServiceRequest: MEV3ApiRequest,
    storage: MEWebPushDb
  ) {
    return new MEClientService(baseUrl, meClientServiceRequest, storage)
  }
}
