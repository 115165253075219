import MeWebPushSdkSingleton from './lib/me-web-push'
import { getGlobal } from './lib/utils'

function main () {
  const global = getGlobal()

  let { WebEmarsysSdk } = global
  let predefinedCommands

  if (WebEmarsysSdk) {
    predefinedCommands = WebEmarsysSdk
  }

  WebEmarsysSdk = MeWebPushSdkSingleton.create(window, navigator)
  if (Array.isArray(predefinedCommands)) {
    predefinedCommands.forEach(c => WebEmarsysSdk.push(c))
  }

  global.WebEmarsysSdk = WebEmarsysSdk
}

if (document.readyState === 'complete') {
  main()
} else {
  window.addEventListener('load', main)
}
