import * as CONSTANTS from './constants'

export function getRegistrationStatus (): string | undefined {
  const status: string | null = localStorage.getItem(CONSTANTS.KEY_DEVICE_REGISTRATION_STATUS)
  if (status) {
    return status
  } else {
    return undefined
  }
}

export function setRegistrationStatus (status: string): void {
  localStorage.setItem(CONSTANTS.KEY_DEVICE_REGISTRATION_STATUS, status)
}

export function removeRegistrationStatus (): void {
  localStorage.removeItem(CONSTANTS.KEY_DEVICE_REGISTRATION_STATUS)
}
