import { MEWebPushDb } from './me-web-push-db'
import * as JWT from './jwt'

export type DeviceCheckResult = {
  exists: boolean
  pushTokenExists?: boolean
  identified?: boolean
}

export function getVersion () {
  return __VERSION__
}

export function getGlobal () {
  /* eslint-disable */
  return Function('return this')()
}

function base64ToBinary (base64String: string): string {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  return atob(base64)
}

export function urlB64ToUint8Array (base64String: string): Uint8Array {
  const rawData = base64ToBinary(base64String)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}

export function urlB64ToString (base64String: string): string {
  return base64ToBinary(base64String)
}

export async function isDeviceRegistered (meWebPushDb: MEWebPushDb): Promise<boolean> {
  const { exists, pushTokenExists } = await checkDevice(meWebPushDb)
  const hasPushToken = pushTokenExists !== undefined ? pushTokenExists : false
  return (exists && hasPushToken)
}

/**
 * Returns basic information about registration, push token and identified contact
 * based on the content of the client state
 */
export async function checkDevice (meWebPushDb: MEWebPushDb): Promise<DeviceCheckResult> {
  const clientId = await meWebPushDb.getClientId()

  if (!clientId) {
    return { exists: false }
  }

  const clientState = await meWebPushDb.getClientState()
  if (!clientState) {
    return { exists: false }
  }

  const decodedClientState = JWT.decode(clientState)
  if (!decodedClientState) {
    return { exists: false }
  }

  const pushToken = decodedClientState.pushToken || null
  const contactField = decodedClientState.contactField || null
  return {
    exists: true,
    pushTokenExists: pushToken !== null,
    identified: contactField !== null
  }
}

export const payloadMessageDataProperties: {[index: string]: string} = {
  id: 'string',
  sid: 'string',
  applicationCode: 'string',
  notificationSettings: 'object'
}

/**
 * Check if the WebPush Message payload is produced by Emarsys.
 * @param payload
 * @returns true if it is an Emarsys Payload
 */
export function isValidPayload (payload: any): boolean {
  if (typeof payload !== 'object') return false
  const emarsysPayload = payload.messageData
  if (!emarsysPayload) return false
  const isValidProperty = (prop: string) => {
    return emarsysPayload[prop]
      ? (typeof emarsysPayload[prop]) === payloadMessageDataProperties[prop]
      : false
  }
  const result = Object.keys(payloadMessageDataProperties)
    .map(isValidProperty)
    .reduce((acc, r) => acc && r, true)
  return result
}

/**
 * Checks the passed data and returns either the ContactInfo or undefined if the data does not fit
 * @param contactInfo The data which shall be converted
 */
export function toContactInfo (contactInfo?: {} | ContactInfo | undefined): ContactInfo | undefined {
  if (contactInfo === undefined || Object.keys(contactInfo).length !== 3) {
    return undefined
  }
  return contactInfo as ContactInfo
}

export const isEmptyObject = (obj: any) => {
  if (obj === null) return true
  /* eslint-disable */
  if (obj === undefined) return true
  for (const property in obj) {
    return false
  }
  return true
}

export function determineServiceWorkerScope (params?: ServiceWorkerParams): string | undefined {
  if (params) {
    return params.scope
  } else {
    return undefined
  }
}
