const defaultHeaders: { [key: string]: string } = {
  'Content-Type': 'application/json'
}

export type HeaderData = {
  clientId: string
  clientState?: string
  contactToken?: string
}

export class MEV3ApiRequest {
  private requestOrder: number

  constructor () {
    this.requestOrder = 0
  }

  async post (endpoint: string, body: object, headerData: HeaderData): Promise<Response> {
    const requestInit: RequestInit = this.createPostRequestInit(body, headerData)
    return fetch(endpoint, requestInit)
  }

  async put (endpoint: string, body: object, headerData: HeaderData): Promise<Response> {
    const requestInit: RequestInit = this.createPutRequestInit(body, headerData)
    return fetch(endpoint, requestInit)
  }

  async delete (endpoint: string, body: object, headerData: HeaderData): Promise<Response> {
    const requestInit: RequestInit = this.createDeleteRequestInit(body, headerData)
    return fetch(endpoint, requestInit)
  }

  private createPostRequestInit (body: object, headerData: HeaderData): RequestInit {
    return this.createRequestInit('POST', body, headerData)
  }

  private createPutRequestInit (body: object, headerData: HeaderData): RequestInit {
    return this.createRequestInit('PUT', body, headerData)
  }

  private createDeleteRequestInit (body: object, headerData: HeaderData): RequestInit {
    return this.createRequestInit('DELETE', body, headerData)
  }

  private createRequestInit (method: string, body: object, headerData: HeaderData): RequestInit {
    return {
      method,
      headers: this.buildHeaders(headerData),
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(body)
    }
  }

  private buildHeaders (headerData: HeaderData) {
    const headers: Headers = new Headers()

    Object.keys(defaultHeaders).forEach(k => {
      headers.append(k, defaultHeaders[k])
    })

    headers.append('x-client-id', headerData.clientId)

    if (headerData.clientState) {
      headers.append('x-client-state', headerData.clientState)
    }

    if (headerData.contactToken) {
      headers.append('x-contact-token', headerData.contactToken)
    }

    headers.append('x-request-order', `${this.requestOrder}`)
    this.requestOrder += 1

    return headers
  }

  static create (): MEV3ApiRequest {
    return new MEV3ApiRequest()
  }
}
