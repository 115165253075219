
import * as CONSTANTS from '../constants'

import logging from '../logging'
import { IPushService } from './push-service.types'
import { MEWebPushDb } from '../me-web-push-db'
import { MEClientService } from '../me-client-service'
import * as Cache from '../local-storage'
import * as Utils from '../utils'

export class SafariPushService implements IPushService {
  private readonly webPushDb: MEWebPushDb
  private readonly config: ApnsApiRegistrationConfig
  private readonly meClientService: MEClientService

  constructor (
    webPushDb: MEWebPushDb,
    config: ApnsApiRegistrationConfig,
    meClientService: MEClientService
  ) {
    this.webPushDb = webPushDb
    this.config = config
    this.meClientService = meClientService
  }

  public getPermission (): NotificationPermission {
    const { permission } = this.getPermissionInfo()
    return permission
  }

  public isPermissionGranted (): boolean {
    return this.getPermission() === CONSTANTS.PERMISSION_GRANTED
  }

  public isPermissionDefault (): boolean {
    return this.getPermission() === CONSTANTS.PERMISSION_PROMPT
  }

  public async askPermission (): Promise<NotificationPermission> {
    const application = this.config.applicationCode
    const clientId = this.config.clientId
    const payload = { application, clientId }

    return new Promise(resolve => {
      safari.pushNotification.requestPermission(
        this.config.safariPushPackageServiceUrl,
        this.config.safariWebsitePushID,
        payload,
        (obj: TSafariPermissionObject) => resolve(obj.permission)
      )
    })
  }

  public async getPushToken (): Promise<WebPushToken | undefined> {
    return this.webPushDb.getPushToken()
  }

  public async subscribe (contactInfo?: {} | ContactInfo): Promise<void> {
    const isPermissionGranted = this.isPermissionGranted()
    if (!isPermissionGranted) {
      logging.Logger.error('Permission must be granted before subscribing!')
      return
    }

    const { deviceToken } = this.getPermissionInfo()
    if (deviceToken) {
      logging.Logger.debug('Got device token:', deviceToken)

      const storedPushToken = await this.getPushToken()

      if (storedPushToken !== deviceToken) {
        logging.Logger.debug('Registering device, linking and registering push token')
        if (
          await this.meClientService.storeClientDetails() &&
          await this.meClientService.linkClientToContact(Utils.toContactInfo(contactInfo)) &&
          await this.meClientService.registerPushToken(deviceToken)
        ) {
          // persist in local DB after all actions were successful
          await this.webPushDb.setPushToken(deviceToken)
          Cache.setRegistrationStatus(CONSTANTS.DEVICE_REGISTRATION_STATUS_REGISTERED)
        }
      }
    } else {
      await this.webPushDb.setPushToken(deviceToken)
      Cache.setRegistrationStatus(CONSTANTS.DEVICE_REGISTRATION_STATUS_UNREGISTERED)
    }
  }

  public async unsubscribe (): Promise<void> {
    // remove token
    await this.meClientService.removePushToken()
    await this.webPushDb.setPushToken(undefined)
    Cache.setRegistrationStatus(CONSTANTS.DEVICE_REGISTRATION_STATUS_UNREGISTERED)
  }

  public async isRegistered (): Promise<boolean> {
    return Utils.isDeviceRegistered(this.webPushDb)
  }

  public async isResubscribeNeeded (): Promise<boolean> {
    const savedWebSitePushId = await this.webPushDb.getWebsitePushId()
    const savedWebSitePushIdExists = typeof savedWebSitePushId !== 'undefined'
    const webSitePushIdChanged = savedWebSitePushIdExists && this.config.safariWebsitePushID !== savedWebSitePushId

    await this.webPushDb.setWebsitePushId(this.config.safariWebsitePushID)

    // check change permission status
    const lastPermission = await this.webPushDb.getLastPermissionStatus()
    const permission = this.getPermission()

    if (lastPermission !== permission) {
      await this.webPushDb.setLastPermissionStatus(permission)
      return true
    }

    return (webSitePushIdChanged || !savedWebSitePushIdExists)
  }

  private getPermissionInfo (): TSafariPermissionObject {
    return safari.pushNotification.permission(this.config.safariWebsitePushID)
  }
}
