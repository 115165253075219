import { BrowserVersionParser } from './browser-version-parser'

export type BrowserName =
  | 'edge'
  | 'samsung'
  | 'chrome'
  | 'firefox'
  | 'safari'
  | 'opera'

type UserAgentRule = [BrowserName, RegExp]

const browserRules: UserAgentRule[] = [
  ['edge', /Edge\/([0-9._]+)/],
  ['edge', /EdgiOS\/([0-9._]+)/],
  ['edge', /EdgA?\/([0-9.]+)/],
  ['samsung', /SamsungBrowser\/([0-9.]+)/],
  ['chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
  ['firefox', /Firefox\/([0-9.]+)(?:\s|$)/],
  ['opera', /Opera Mini.*Version\/([0-9.]+)/],
  ['opera', /Opera\/([0-9.]+)(?:\s|$)/],
  ['opera', /OPR\/([0-9.]+)(:?\s|$)/],
  ['safari', /Version\/([0-9._]+).*Safari/]
]

const browserNameMapping: { [key: string]: BrowserName} = {
  samsung: 'chrome',
  opera: 'chrome'
}

export type GetAllResult = {
  userAgent: string
  browser: string
  browserVersion: string
  timezone: string
  language: string
}

export class NavigatorInfo {
  private navigator: Navigator

  constructor (navig: Navigator) {
    this.navigator = navig
  }

  /**
   * Get environment info like browserVersion language etc.
   */
  getAll (): GetAllResult {
    return {
      userAgent: this.getUserAgent(),
      browser: this.getBrowserName(),
      browserVersion: this.getVersion(),
      timezone: this.getTimezone(),
      language: this.getLanguage()
    }
  }

  hasServiceWorker (): boolean {
    return ('serviceWorker' in this.navigator)
  }

  private getUserAgent (): string {
    return this.navigator.userAgent
  }

  private getBrowserName (): string {
    let browserName: BrowserName = 'chrome'
    const nAgt = this.navigator.userAgent
    for (const rule of browserRules) {
      const [name, regex] = rule
      const match = regex.exec(nAgt)
      if (match) {
        browserName = name
        break
      }
    }
    return this.getMappedBrowserName(browserName)
  }

  private getMappedBrowserName (name: BrowserName): BrowserName {
    const mapped = browserNameMapping[name]
    if (mapped) {
      return mapped
    } else {
      return name
    }
  }

  private getVersion (): string {
    return BrowserVersionParser.parseBrowserVersion(this.navigator)
  }

  private getTimezone (): string {
    const offset = new Date().getTimezoneOffset()
    const timezone = ((offset < 0 ? '+' : '-') +
      this.pad(Math.abs(offset / 60), 2) +
      this.pad(Math.abs(offset % 60), 2))
    return timezone
  }

  private getLanguage () {
    return this.navigator.language
  }

  /**
   * Convert number to string and pad the string with zeros to be the required length
   */
  private pad (nr: number, length: number): string {
    let str = nr.toString()
    while (str.length < length) {
      str = '0' + str
    }
    return str
  }

  static create (navig: Navigator): NavigatorInfo {
    return new NavigatorInfo(navig)
  }
}
