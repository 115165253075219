import logging from './logging'
import { MEWebPushDb } from './me-web-push-db'
const { Logger } = logging

type Params = {
  level?: string
  message?: string
}

export class StorageWithLog {
  private readonly storage: MEWebPushDb

  constructor (storage: MEWebPushDb) {
    this.storage = storage
  }

  private log (value: string | undefined, defaultMessage: string, params?: Params) {
    const { level = 'error', message = defaultMessage } = params ?? {}
    if (!value) Logger[level](message)
    return value
  }

  async getClientId (params?: Params): Promise<string | undefined> {
    const defaultMessage = 'Error: No client ID set for the browser!'
    const clientId = await this.storage.getClientId()
    return this.log(clientId, defaultMessage, params)
  }

  async getContactToken (params?: Params): Promise<string | undefined> {
    const defaultMessage = 'Error: No contact token set!'
    const contactToken = await this.storage.getContactToken()
    return this.log(contactToken, defaultMessage, params)
  }

  async getClientState (params?: Params): Promise<string | undefined> {
    const defaultMessage = 'Error: No client state set!'
    const clientState = await this.storage.getClientState()
    return this.log(clientState, defaultMessage, params)
  }

  async getRefreshToken (params?: Params): Promise<string | undefined> {
    const defaultMessage = 'Unable to refresh contact token a refresh-token is missing!'
    const refreshToken = await this.storage.getRefreshToken()
    return this.log(refreshToken, defaultMessage, params)
  }
}
