import { v4 as uuidv4 } from 'uuid'

import * as CONSTANTS from '../constants'
import logging from '../logging'
import { IPushService } from './push-service.types'
import { MEWebPushDb } from '../me-web-push-db'
import { MEClientService } from '../me-client-service'
import * as Cache from '../local-storage'
import * as Utils from '../utils'

export class VapidPushService implements IPushService {
  private registration?: ServiceWorkerRegistration
  private readonly webPushDb: MEWebPushDb
  private readonly meClientService: MEClientService

  constructor (
    webPushDb: MEWebPushDb,
    meClientService: MEClientService
  ) {
    this.webPushDb = webPushDb
    this.meClientService = meClientService
  }

  public getPermission (): NotificationPermission {
    return Notification.permission
  }

  public isPermissionGranted (): boolean {
    return this.getPermission() === CONSTANTS.PERMISSION_GRANTED
  }

  public isPermissionDefault (): boolean {
    return this.getPermission() === CONSTANTS.PERMISSION_PROMPT
  }

  public async askPermission (): Promise<NotificationPermission> {
    return Notification.requestPermission()
  }

  public async getPushToken (): Promise<WebPushToken | undefined> {
    return this.webPushDb.getPushToken()
  }

  public async subscribe (contactInfo?: {} | ContactInfo): Promise<void> {
    const isPermissionGranted = this.isPermissionGranted()

    if (!isPermissionGranted) {
      logging.Logger.error('Permission must be granted before subscription!')
      return
    }

    // get browser subscription
    const subscription = await this.getPushSubscription()
    if (subscription !== null) {
      const pushToken = JSON.stringify(subscription)
      logging.Logger.debug('Got valid subscription:', pushToken)

      const storedPushToken = await this.getPushToken()
      if (storedPushToken !== pushToken) {
        logging.Logger.debug('Registering device, linking and registering push token')
        if (
          await this.meClientService.storeClientDetails() &&
          await this.meClientService.linkClientToContact(Utils.toContactInfo(contactInfo)) &&
          await this.meClientService.registerPushToken(pushToken)
        ) {
          // persist in local DB after all actions were successful
          await this.webPushDb.setPushToken(pushToken)
          Cache.setRegistrationStatus(CONSTANTS.DEVICE_REGISTRATION_STATUS_REGISTERED)
        }
      }
    } else {
      logging.Logger.warn('Issue fetching the actual push token (subscription).')
    }
  }

  public async unsubscribe (): Promise<void> {
    logging.Logger.log('VAPID: unsubscribe')
    // get service worker registration
    const registration = await this.getServiceWorkerRegistration()

    logging.Logger.log('VAPID: registration', registration)
    // get current subscription
    const subscription = await registration.pushManager.getSubscription()
    logging.Logger.log('VAPID: subscription', subscription)

    // remove token
    await this.meClientService.removePushToken()
    await this.webPushDb.setPushToken(undefined)

    if (!subscription) {
      return
    }

    await subscription.unsubscribe()
    Cache.setRegistrationStatus(CONSTANTS.DEVICE_REGISTRATION_STATUS_UNREGISTERED)
    // TODO: await this.unregisterDevice()
  }

  public async isRegistered (): Promise<boolean> {
    return Utils.isDeviceRegistered(this.webPushDb)
  }

  public async isResubscribeNeeded (): Promise<boolean> {
    // check change permission status
    const lastPermission = await this.webPushDb.getLastPermissionStatus()
    const permission = this.getPermission()

    if (lastPermission !== permission) {
      await this.webPushDb.setLastPermissionStatus(permission)
    }
    return (lastPermission !== permission)
  }

  public async updateServiceWorker (): Promise<ServiceWorkerRegistration> {
    return this.registerServiceWorker()
  }

  private async getServiceWorkerRegistration (): Promise<ServiceWorkerRegistration> {
    if (!this.registration) {
      logging.Logger.debug('No service worker found. Registering service worker...')
      this.registration = await this.registerServiceWorker()

      // eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
      if (!this.registration) {
        throw new Error('Internal Error: Can\'t register service worker!')
      } else {
        logging.Logger.log('Service worker is registered')
        await this.registration.update()
      }
    }

    return this.registration
  }

  private async registerServiceWorker (): Promise<ServiceWorkerRegistration> {
    const url = await this.webPushDb.getServiceWorkerUrl()
    const scope = await this.webPushDb.getServiceWorkerScope()
    const sdkVersion = await this.webPushDb.getSdkVersion()
    const serviceWorkerVersion = await this.webPushDb.getServiceWorkerVersion()

    // add clean cache get parameter if sdk version and service worker
    // version is not the same to trigger an update of the service worker
    const cleanCache = (sdkVersion !== serviceWorkerVersion) ? `?cache_clean=${uuidv4()}` : ''
    const options = scope ? { scope } : undefined

    return navigator
      .serviceWorker
      .register(`/${url!}${cleanCache}`, options)
  }

  private async getPushSubscription (isRetry: boolean = false): Promise<PushSubscription | null> {
    const registration = await this.getServiceWorkerRegistration()
    const applicationServerKey = await this.getApplicationServerKey()

    const existingSubscription = await registration.pushManager.getSubscription()
    const subscription = existingSubscription !== null
      ? existingSubscription
      : await registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey })

    if (subscription.endpoint.length > 0) {
      return subscription
    } else {
      if (isRetry) {
        logging.Logger.warn('Retry to get subscription without empty endpoint failed. Giving up.')
        return null
      } else {
        return this.handleSubscriptionWithEmptyEndpoint(subscription)
      }
    }
  }

  private async handleSubscriptionWithEmptyEndpoint (subscription: PushSubscription): Promise<PushSubscription | null> {
    logging.Logger.warn('Got subscription with empty endpoint', subscription)
    const success = await subscription.unsubscribe()

    if (!success) {
      logging.Logger.warn('Unable to unsubscribe from subscription with empty endpoint')
      return null
    }

    logging.Logger.debug('Retrying to get a subscription without an empty endpoint')
    return this.getPushSubscription(true)
  }

  private async getApplicationServerKey (): Promise<string | undefined> {
    return this.webPushDb.getApplicationServerPublicKey()
  }
}
